import refreshToken from '@/plugins/refresh-token'
import { api } from '../mainNode'

// Node api All Functions
const memberList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'member/list',
      body,
    })
    .then(res => res)
    .catch(err => {
      console.error('get member err : ', err)

      return {}
    })

  return response
}
const memberAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'member',
      body,
    })
    .then(res => res)
    .catch(err => {
      console.error('add member err : ', err)

      return {}
    })

  return response
}

const memberUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'member',
      body,
    })
    .then(res => res)
    .catch(err => {
      console.error('update member err : ', err)

      return {}
    })

  return response
}

const memberStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'member/status',
      body,
    })
    .then(res => res)
    .catch(err => {
      console.error('update status member err : ', err)

      return {}
    })

  return response
}

export default {
  memberList,
  memberAdd,
  memberUpdate,
  memberStatusUpdate,
}
