<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Manage Web Promotion') }} <v-spacer></v-spacer>
        <v-btn color="primary" class="me-2 d-none d-md-block" @click="isAddNewPromotionCourse = true">
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('add') }}{{ $t('course') }}
        </v-btn>
        <v-btn color="primary" class="me-2 d-block d-md-none" icon fab outlined @click="isAddNewPromotionCourse = true">
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
        <v-btn color="info" class="d-none d-md-block me-2" @click="isAddNewPromotionDrug = true">
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('add') }}{{ $t('drug_equipment') }}
        </v-btn>
        <v-btn color="info" class="d-block d-md-none me-2" fab outlined icon @click="isAddNewPromotionDrug = true">
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
        <v-btn color="warning" class="d-none d-md-block" @click="isAddNewPromotionMember = true">
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('add') }}{{ $t('Member Card') }}
        </v-btn>
        <v-btn color="warning" class="d-block d-md-none" fab outlined icon @click="isAddNewPromotionMember = true">
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-row class="px-2">
        <v-col cols="12" md="6" lg="3" class="py-0">
          <v-text-field v-model="searchtext" :label="$t('search')" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="py-0">
          <v-select
            v-model.trim="checkSelect"
            :items="checkData"
            :label="$t('checkingStatus')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="py-0">
          <v-select
            v-model.trim="statusSelectd"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="3" class="py-0">
          <v-select
            v-model.trim="exSelect"
            :items="exData"
            :label="$t('ExpirationStatus')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.crm_product_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.crm_product_image`]="{ item }">
          <v-avatar class="my-1 rounded-lg elevation-1" tile>
            <v-img :src="item.crm_product_image" />
          </v-avatar>
        </template>
        <template v-slot:[`item.crm_product_name`]="{ item }">
          {{ item.crm_product_name }}
          <v-chip
            v-if="item.crm_product_type_id == 2"
            small
            color="primary"
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg primary--text px-3`"
          >
            <v-icon class="text-white" left small>
              {{ icons.mdiPill }}
            </v-icon>
            /
            <v-icon class="text-white" right small>
              {{ icons.mdiNeedle }}
            </v-icon>
          </v-chip>
          <v-chip
            v-if="item.crm_product_type_id == 1"
            small
            color="accent"
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg accent--text px-2`"
          >
            <v-icon class="text-white" small>
              {{ icons.mdiAutoFix }}
            </v-icon>
          </v-chip>
          <v-chip
            v-if="item.crm_product_type_id == 3"
            small
            color="accent"
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg error--text px-2`"
          >
            <v-icon class="text-white" small>
              {{ icons.mdiCardAccountDetailsStarOutline }}
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:[`item.crm_product_cost`]="{ item }">
          {{ item.crm_product_cost | formatPrice }}
        </template>
        <template v-slot:[`item.crm_product_total`]="{ item }">
          {{ item.crm_product_total | formatPrice }}
        </template>
        <template v-slot:[`item.crm_product_start`]="{ item }">
          <ConverseDate :date="item.crm_product_start" />
        </template>
        <template v-slot:[`item.crm_product_end`]="{ item }">
          <ConverseDate :date="item.crm_product_end" />
        </template>

        <template v-slot:[`item.crm_product_group_id`]="{ item }">
          {{ item.crm_product_group_id == '1' ? $t('normal') : $t('birth_month_promotion') }}
        </template>

        <template v-slot:[`item.crm_product_active_id`]="{ item }">
          <v-chip
            v-if="item.crm_product_active_id == '0'"
            color="error"
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text`"
            small
          >
            {{ $t('waiting_check') }}
          </v-chip>
          <v-chip
            v-if="item.crm_product_active_id == '1'"
            color="error"
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg success--text`"
            small
          >
            {{ $t('checked') }}
          </v-chip>
          <v-chip
            v-if="item.crm_product_active_id == '2'"
            color="error"
            :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg error--text`"
            small
          >
            {{ $t('failed') }}
          </v-chip>
        </template>

        <template v-slot:[`item.crm_product_status_id`]="{ item }">
          <StatusBlock :status="String(item.crm_product_status_id)" />
        </template>

        <template v-slot:[`item.option`]="{ item }">
          <v-tooltip color="#212121" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="
                  item.crm_product_type_id == 2
                    ? (isEditPromotionDrug = true)
                    : item.crm_product_type_id == 1
                    ? (isEditPromotionCourse = true)
                    : (isEditPromotionMember = true)
                  prodcutId = item.crm_product_id
                "
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          <v-tooltip color="#212121" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="
                  prodcutId = item.crm_product_id
                  promotionStatus = item.crm_product_status_id
                  isUpdateStatus = true
                "
                v-on="on"
              >
                <v-icon>{{ item.crm_product_status_id == '2' ? icons.mdiCheck : icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.crm_product_status_id == '2' ? $t('normal') : $t('suspend') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId"
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="
          page => {
            options.page = page
            fetchDataTable()
          }
        "
        @itemChanged="
          items => {
            options.itemsPerPage = items
            options.page = 1
            fetchDataTable()
          }
        "
      />
    </v-card>

    <v-dialog v-model="isUpdateStatus" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon color="warning" class="me-1">
            {{ icons.mdiAlertOutline }}
          </v-icon>
          {{ promotionStatus == 1 ? $t('confirmSuspendPromotion') : $t('cancelSuspendPromotion') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" :loading="processUpdate" :disabled="processUpdate" @click="updateStatus">
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color="secondary" outlined @click="isUpdateStatus = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isSendCheck" persistent max-width="300">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          {{ $t('confirm_send_check') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="loadingSend" :disabled="loadingSend" @click="sendCheck">
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color="secondary" outlined @click="isSendCheck = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddNewPromotionCourse v-model="isAddNewPromotionCourse" @onAdd="fetchDataTable" />
    <AddNewPromotionDrugEquipment v-model="isAddNewPromotionDrug" @onAdd="fetchDataTable" />
    <EditPromotionCourse v-model="isEditPromotionCourse" :product-id="String(prodcutId)" @onUpdate="fetchDataTable" />
    <EditPromotionDrug v-model="isEditPromotionDrug" :product-id="String(prodcutId)" @onUpdate="fetchDataTable" />
    <AddNewPromotionMember v-model="isAddNewPromotionMember" :product-id="String(prodcutId)" />
    <EditPromotionMember v-model="isEditPromotionMember" :product-id="String(prodcutId)" @onUpdate="fetchDataTable" />
  </div>
</template>

<script>
import usePromotionManament from './usePromotionManament'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import { formatPrice } from '@/plugins/filters'
import AddNewPromotionCourse from './AddNewPromotionCourse.vue'
import AddNewPromotionDrugEquipment from './AddNewPromotionDrugEquipment.vue'
import AddNewPromotionMember from './AddNewPromotionMember.vue'
import EditPromotionCourse from './EditPromotionCourse.vue'
import EditPromotionDrug from './EditPromotionDrug.vue'
import EditPromotionMember from './EditPromotionMember.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    EditPromotionMember,
    AddNewPromotionMember,
    Pagination,
    ConverseDate,
    StatusBlock,
    AddNewPromotionCourse,
    AddNewPromotionDrugEquipment,
    EditPromotionCourse,
    EditPromotionDrug,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      ...usePromotionManament(),
    }
  },
}
</script>
