<template>
  <v-dialog v-model="isAddNewPromotionMember" persistent max-width="1200">
    <v-card>
      <v-card-title> {{ $t('edit') }}{{ $t('Member Card') }} </v-card-title>
      <v-form ref="formAddPromotionMember" @submit.prevent="updatePromotionMember">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="5" lg="4">
              <v-text-field v-model="product_name" :label="$t('name')" readonly outlined dense></v-text-field>
              <input ref="selectImage" type="file" style="display: none" @change="previewFiles" />
              <p class="error--text">
                {{ $t('NoteGallery600600') }}
              </p>
              <v-hover v-slot="{ hover }">
                <v-card
                  v-if="!blob_image"
                  :elevation="hover ? 5 : 2"
                  :class="{ 'on-hover': hover }"
                  class="mx-auto upload-files"
                  height="245px"
                  @click="$refs.selectImage.click()"
                >
                  <div align="center" class="pt-12">
                    <div class="text-lg">
                      {{ $t('UploadImage') }}
                    </div>
                    <div class="text-md error--text">
                      {{ $t('maximumImage') }}
                    </div>
                    <v-icon x-large color="primary" class="pt-2">
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                  </div>
                </v-card>
                <v-img v-else class="rounded-lg set-block" :src="blob_image" height="245px">
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="#212121">
                      <div class="align-self-center">
                        <v-btn :class="{ 'show-btns': hover }" icon color="primary" @click="$refs.selectImage.click()">
                          <v-icon :class="{ 'show-btns': hover }" :color="transparent">
                            {{ icons.mdiPencilOutline }}
                          </v-icon>
                        </v-btn>
                        <v-btn :class="{ 'show-btns': hover }" icon color="primary" @click="blob_image = ''">
                          <v-icon :class="{ 'show-btns': hover }" :color="transparent">
                            {{ icons.mdiDeleteOutline }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-hover>
              <!-- <v-img
                :src="blob_image"
                height="400px"
                class="rounded-lg"
                alt=""
              ></v-img>
              <v-btn
                class="mt-3"
                color="primary"
                block
                @click="$refs.selectImage.click()"
              >
                {{ $t("UploadImage") }}
              </v-btn> -->
              <v-text-field
                v-model.trim="product_vdo"
                class="mt-3"
                :label="$t('RecommendedvideoYoutubelink')"
                outlined
                dense
              >
                <template v-slot:prepend>
                  <v-icon color="error">
                    {{ icons.mdiYoutube }}
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="7" lg="8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="categorySelect"
                    :no-data-text="$t('no_information')"
                    :items="categoryData"
                    :label="$t('memberType')"
                    dense
                    outlined
                    hide-details="auto"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="course_id_pri"
                    :no-data-text="$t('no_information')"
                    dense
                    outlined
                    hide-details="auto"
                    :items="memberData"
                    :rules="[required]"
                    :label="$t('Member Card')"
                    item-text="name"
                    return-object
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    v-model.trim="product_amount"
                    :label="$t('quantity')"
                    type="number"
                    disabled
                    hide-details="auto"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    v-model.trim="product_unit"
                    :label="$t('unit')"
                    readonly
                    outlined
                    hide-details="auto"
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    v-model.trim="product_cost"
                    :label="$t('price')"
                    readonly
                    disabled
                    hide-details="auto"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    v-model.trim="product_total"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('sale_price')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    v-model.trim="product_shop_point"
                    :label="$t('receive_points')"
                    outlined
                    dense
                    hide-details="auto"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-autocomplete
                    v-model.trim="product_group_id"
                    :items="promotionTypeList"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('promotion_type')"
                    :no-data-text="$t('no_information')"
                    :item-text="$i18n.locale"
                    item-value="value"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-dialog
                    ref="selectDate1"
                    v-model.trim="isShowDate1"
                    :return-value.sync="product_start"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model.trim="product_start"
                        :label="$t('date')"
                        outlined
                        dense
                        :rules="[required]"
                        hide-details="auto"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model.trim="product_start" class="set-font" :locale="$i18n.locale" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.selectDate1.save(product_start)">
                        {{ $t('confirm') }}
                      </v-btn>
                      <v-btn text color="secondary" @click="isShowDate1 = false">
                        {{ $t('cancel') }}
                      </v-btn>

                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6">
                  <v-dialog ref="selectDate2" v-model.trim="isShowDate2" :return-value.sync="product_end" width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model.trim="product_end"
                        class="set-font"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[required]"
                        :label="$t('to')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model.trim="product_end" class="set-font" :locale="$i18n.locale" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.selectDate2.save(product_end)">
                        {{ $t('confirm') }}
                      </v-btn>
                      <v-btn text color="secondary" @click="isShowDate2 = false">
                        {{ $t('cancel') }}
                      </v-btn>

                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <label>{{ $t('detail') }}</label>
                  <quill-editor ref="myTextEditor" v-model="product_detail" :options="editorOption"> </quill-editor>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit" :loading="loading" :disabled="loading || !blob_image">
            {{ $t('save') }}
          </v-btn>
          <v-btn color="secondary" outlined @click="$emit('update:is-add-new-promotion-member', false)">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiYoutube, mdiCloudUploadOutline, mdiPencilOutline, mdiDeleteOutline } from '@mdi/js'
import { quillEditor } from 'vue-quill-editor'
import { ref, watch, onMounted } from '@vue/composition-api'
import PromotionType from '@/@fake-db/data/PromotionType.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { required } from '@/@core/utils/validation'
import crmLine from '@/api/CRM-line/crmLine'
import memberType from '@/api/member/memberType'
import member from '@/api/member/member'

export default {
  components: {
    quillEditor,
  },
  model: {
    prop: 'isAddNewPromotionMember',
    event: 'update:is-add-new-promotion-member',
  },
  props: {
    isAddNewPromotionMember: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
    productId: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const moment = require('moment')
    const promotionTypeList = ref(PromotionType.data)
    const course_id_pri = ref('')
    const product_group_id = ref('')
    const product_name = ref('')
    const product_detail = ref('')
    const product_amount = ref('')
    const product_unit = ref('')
    const product_cost = ref('')
    const product_total = ref('')
    const product_shop_point = ref('')
    const product_end = ref('')
    const product_vdo = ref('')
    const product_image = ref('')
    const blob_image = ref('')
    const dialog = ref(false)
    const serviceTypeData = ref([])
    const product_category_id = ref('')
    const categorySelect = ref('')
    const memberData = ref([])
    const transparent = ref('rgba(255, 255, 255, 0)')
    const categoryData = ref([])
    const product_start = ref('')
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)
    const editorOption = ref({
      placeholder: '',
    })
    const formAddPromotionMember = ref(null)
    const loading = ref(false)

    onMounted(() => {
      getMemberType()
    })

    const getPromotion = () => {
      loading.value = true
      crmLine.productGet(props.productId).then(res => {
        mapData(res.data)
      })
    }

    const mapData = product => {
      product_category_id.value = []
      course_id_pri.value = memberData.value.find(member => member.id == product.member_id_pri)
      product_group_id.value = product.crm_product_group_id
      product_name.value = product.crm_product_name
      product_detail.value = product.crm_product_detail
      product_amount.value = product.crm_product_amount
      product_unit.value = product.crm_product_unit
      product_cost.value = product.crm_product_cost
      product_total.value = product.crm_product_total
      product_shop_point.value = product.crm_product_shop_point
      product_start.value = product.crm_product_start
      product_end.value = product.crm_product_end
      product_vdo.value = product.crm_product_vdo
      if (product.crm_product_category) {
        product.crm_product_category.forEach(item => {
          product_category_id.value.push(item.crm_product_category_id)
        })
      }
      product_image.value = ''

      //   product_image.value = product.crm_product_image ? product.crm_product_image : ''
      blob_image.value = product.crm_product_image ? product.crm_product_image : ''
      loading.value = false
    }

    const getMemberList = () => {
      member
        .memberList({
          searchtext: '',
          member_type_id: categorySelect.value,
          status: '1',
        })
        .then(res => {
          memberData.value = res.data
        })
    }

    const getMemberType = () => {
      memberType
        .memberTypeList({
          searchtext: '',
          status: 1,
        })
        .then(res => {
          categoryData.value = res
          categoryData.value.unshift({
            id: '',
            name: i18n.t('all'),
          })
          categorySelect.value = ''
          getMemberList()
        })
    }

    const previewFiles = event => {
      product_image.value = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(event.target.files[event.target.files.length - 1])
    }

    const updatePromotionMember = () => {
      const isFormValid = formAddPromotionMember.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('crm_product_id', props.productId)
      formData.append('member_id_pri', course_id_pri.value.id)
      formData.append('crm_product_group_id', product_group_id.value)
      formData.append('crm_product_name', product_name.value)
      formData.append('crm_product_detail', product_detail.value)
      formData.append('crm_product_amount', product_amount.value)
      formData.append('crm_product_unit', product_unit.value)
      formData.append('crm_product_cost', product_cost.value)
      formData.append('crm_product_total', product_total.value)
      formData.append('crm_product_shop_point', product_shop_point.value)
      formData.append('crm_product_start', product_start.value)
      formData.append('crm_product_end', product_end.value)
      formData.append('crm_product_vdo', product_vdo.value)
      product_category_id.value.forEach((item, index) => {
        formData.append(`crm_product_category_id[${index}]`, item)
      })
      formData.append('crm_product_image', product_image.value)
      crmLine.productUpdateMember(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getMemberList()
        emit('onUpdate')
        emit('update:is-add-new-promotion-member', false)
        loading.value = false
      })
    }

    watch(
      () => props.isAddNewPromotionMember,
      value => {
        if (value) {
          getPromotion()
        }
      },
    )

    watch(categorySelect, () => {
      getMemberList()
    })

    watch(course_id_pri, value => {
      product_amount.value = 1
      product_unit.value = i18n.t('Card')
      product_cost.value = value.price
      product_total.value = value.price
      product_name.value = value.name
      product_shop_point.value = value.point
      product_start.value = moment(new Date()).format('YYYY-MM-DD')
      product_group_id.value = '1'
    })

    return {
      editorOption,
      promotionTypeList,
      course_id_pri,
      product_group_id,
      product_name,
      product_detail,
      product_amount,
      product_unit,
      product_cost,
      product_total,
      product_shop_point,
      product_end,
      product_vdo,
      product_image,
      blob_image,
      dialog,
      serviceTypeData,
      product_category_id,
      categorySelect,
      categoryData,
      memberData,
      product_start,
      isShowDate2,
      isShowDate1,
      formAddPromotionMember,
      loading,
      transparent,
      updatePromotionMember,
      required,
      previewFiles,
      icons: {
        mdiYoutube,
        mdiCloudUploadOutline,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  padding: 8px;
}

.ql-container {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 263px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px;
  position: relative;
}

.upload-files {
  cursor: pointer;
}
.block-upload {
  background-color: rgb(205, 205, 205);
  border: 1px dashed #212121;
  box-sizing: border-box;
  border-radius: 6px;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
